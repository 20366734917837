 import axios from 'axios';
 import store from 'src/store/Store';


let url="";
if(import.meta.env.VITE_APP_ENVIROMENT_PROD==false){
  console.log(
    import.meta.env.VITE_APP_SERVER_IP_LOCAL
   )
  url=import.meta.env.VITE_APP_SERVER_IP_LOCAL;
}else{
  url=import.meta.env.VITE_APP_SERVER_IP;

}
 const axiosServices = axios.create({baseURL:url});


 // Interceptor para inyectar el token en las solicitudes salientes
axiosServices.interceptors.request.use((config) => {
    // Obtén el estado actual de Redux store
    const state = store.getState();
    // Obtén el token de alguna parte del estado, por ejemplo state.auth.token
    const token = state.userdata.token;
  
    // Si hay un token, adjúntalo en la cabecera de autorización
    if (token) {
      config.headers!['Authorization'] = `Bearer ${token}`;
    }
    
    return config;
  });

 // interceptor for http
 axiosServices.interceptors.response.use(
     (response) => response,
     (error) => {  
        if(error.response.status==401){
            window.location.replace('/auth/login');
        }     
        return Promise.reject((error.response && error.response.data) || 'Wrong Services')
     }
 );
 

//  const addCounter= dispatch(addCounter(failureCount));


 export default axiosServices;
 