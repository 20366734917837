// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';



/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));

/* ****Apps***** */
const Facturas = Loadable(lazy(() => import('../views/apps/facturacion/Facturacion')));
const Versiones = Loadable(lazy(() => import('../views/apps/versiones')));



const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/apps/facturacion" /> },
      { path: '/apps/facturacion', element: <Facturas /> },
      { path: '/apps/versiones', element: <Versiones /> },

   
    ],
  }
 
];

export default Router;
